import { useEffect, useState } from 'react';
import { getErrorMessageV2 } from 'shared/lib';
import { IUser, showError } from 'shared/model';
import { useAuthStore } from 'stores/useAuthStore';

const generateCode = (personaId: string) => {
  const origin = window.location.origin;

  const url = `<iframe width='100%' height='100%' src='${origin}/video-agents/avatars/embed/${personaId}' allow='microphone; camera'/>`;

  return url;
};

export const generateWidgetCode = (personaId: string) => {
  const origin = window.location.origin;

  const urls = [
    `<link rel='stylesheet' href='${origin}/widget/style.css'>
<script src='${origin}/widget/script.js'></script>`,
    `<script>
document.addEventListener('DOMContentLoaded', (event) => {
  initYepicChatWidget({ url: '${origin}/video-agents/avatars/embed/${personaId}' });
});
</script>`,
    `${origin}/video-agents/avatars/event/${personaId}`,
    `${origin}/video-agents/avatars/event2`
  ];

  return urls;
};

export const whereOptions = [
  'nowhere',
  'everywhere',
  'specific domain'
] as const;

export type WhereOptions = typeof whereOptions[number];

export const useTavusGenerateEmbedCode = (personaId: string) => {
  const { apiKey, agentKey } = useAuthStore();
  const [embedCode, setEmbedCode] = useState(generateCode(personaId));
  const [widgetCode, setWidgetCode] = useState(generateWidgetCode(personaId));
  const [allowedDomains, setAllowedDomains] = useState<string[]>([]);
  const [where, setWhere] = useState<WhereOptions>('everywhere');

  useEffect(() => {
    setAllowedDomains([]);
    setEmbedCode(generateCode(personaId));
    setWidgetCode(generateWidgetCode(personaId));
  }, [personaId, agentKey]);

  const onAddDomain = (domain: string) => {
    setAllowedDomains((prev) => [...prev, domain]);
  };

  const handleChangeWhere = (value: WhereOptions) => {
    setWhere(value);
  };

  const removeDomainName = (index: number) => {
    const allowed = [...allowedDomains];
    allowed.splice(index, 1);
    setAllowedDomains(allowed);
  };

  const generateEmbedCode = () => {
    try {
      const newToken = 'someToken'; // TODO: Send request for token

      setEmbedCode(generateCode(personaId));
    } catch (error) {
      const message = getErrorMessageV2(
        error,
        "We couldn't generate embed code",
        true
      );
      showError(message);
    }
  };

  const getWidgetCode = () => {
    // TODO: Refactor with token
  };

  return {
    embedCode,
    allowedDomains,
    onAddDomain,
    generateEmbedCode,
    where,
    handleChangeWhere,
    removeDomainName,
    widgetCode
  };
};
