import { AxiosResponse } from 'axios';
import { tavusHttpService } from 'shared/api';
import { Replica } from '../model';

const replicasEndpoint = '/replica/';

export const replicaApi = {
  fetch: async (id: string) => {
    const { data }: AxiosResponse<Replica> = await tavusHttpService.get(
      `${replicasEndpoint}/${id}`
    );

    return data;
  },
  fetchAll: async (userId: string) => {
    const { data }: AxiosResponse<Replica[]> = await tavusHttpService.get(
      replicasEndpoint,
      {
        params: {
          userId
        }
      }
    );

    return data;
  }
};
