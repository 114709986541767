import axios from 'axios';
import { ENVIRONMENT, TAVUS_BASEURL } from 'shared/model';

import { userStore } from 'entities/user';

export const getHeaders = () => ({});

export const tavusHttpService = axios.create({
  baseURL: TAVUS_BASEURL,
  timeout: 60000,
  headers: {
    env: ENVIRONMENT
  }
});

tavusHttpService.interceptors.request.use(
  (config) => {
    config.headers.Authorization = userStore.getState().user?.internalKey;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
