import { AiOutlineOpenAI } from 'react-icons/ai';

export const aiModels = [
  {
    value: 'tavus-llama',
    name: 'Llama',
    provider: '',
    iconBlack: <AiOutlineOpenAI className="ag-text-2xl ag-text-black" />,
    icon: <AiOutlineOpenAI className="ag-text-2xl ag-text-black" />,
    apiKey: false,
    description:
      'Fastest model, providing the best user-to-user (U2U) experience.'
  },
  {
    value: 'tavus-gpt-4o-mini',
    name: 'GPT-4o Mini',
    provider: '',
    iconBlack: <AiOutlineOpenAI className="ag-text-2xl ag-text-black" />,
    icon: <AiOutlineOpenAI className="ag-text-2xl ag-text-black" />,
    apiKey: false,
    description:
      'Quick responses with basic understanding, ideal for simple queries.'
  },
  {
    value: 'tavus-gpt-4o',
    name: 'GPT-4o',
    provider: '',
    iconBlack: <AiOutlineOpenAI className="ag-text-2xl ag-text-black" />,
    icon: <AiOutlineOpenAI className="ag-text-2xl ag-text-black" />,
    apiKey: false,
    description:
      'Balanced performance with enhanced comprehension for moderate interactions.'
  }
];
