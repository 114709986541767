import { create } from 'zustand';
import { replicaApi } from '../api';
import { toast } from 'react-toastify';
import { Replica } from './replicaTypes';
import { userStore } from 'entities/user';

type State = {
  replicas: Replica[];
  replicasLoading: boolean;
  currentReplica: Replica | null;
};
type Actions = {
  fetchReplicas: () => Promise<void>;
  fetchOne: (id: string) => Promise<Replica | undefined>;
  getReplicaVideo: (id: string) => string;
  getReplicaById: (id: string) => Replica | undefined;
  setCurrentReplica: (replica: Replica | null) => void;
};

type Store = State & Actions;

export const replicaStore = create<Store>((set, get) => ({
  replicas: [],
  replicasLoading: false,
  currentReplica: null,
  setCurrentReplica: (replica) => {
    set({ currentReplica: replica });
  },
  fetchReplicas: async () => {
    set({ replicasLoading: true });
    const userId = userStore.getState().user?.id;
    try {
      const replicas = await replicaApi.fetchAll(userId!);

      set({ replicas });
    } catch (error) {
      toast.error(
        'There was a problem when we tried to get replicas list. Please try again later.'
      );
    } finally {
      set({ replicasLoading: false });
    }
  },
  fetchOne: async (id: string) => {
    set({ replicasLoading: true });
    try {
      return await replicaApi.fetch(id);
    } catch (error) {
      toast.error(
        'There was a problem when we tried to get replica details. Please try again later.'
      );
    } finally {
      set({ replicasLoading: false });
    }
  },
  getReplicaVideo: (id) => {
    const replicas = get().replicas;

    const replica = replicas.find((replica) => replica.replica_id === id);

    if (!replica) {
      return '';
    } else {
      return replica.thumbnail_video_url;
    }
  },
  getReplicaById: (id) => {
    const allReplicas = get().replicas;

    return allReplicas.find((a) => a.replica_id === id);
  }
}));
