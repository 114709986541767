import { create } from 'zustand';
import { stockVideoApi } from '../api';

interface IPexelsVideo {
  id: number;
  width: number;
  height: number;
  url: string;
  image: string;
  duration: string;
  user: {
    id: number;
    name: string;
    url: string;
  };
  video_files: IPexelsVideoFile[];
  video_pictures: [
    {
      id: number;
      picture: string;
      nr: number;
    }
  ];
}

interface IPexelsVideoFile {
  id: number;
  quality: string;
  file_type: string;
  width: number;
  height: number;
  link: string;
}

type Store = {
  error: string;
  stockVideos: IPexelsVideo[];
  isLoaded: boolean;
  currentPage: number;
  currentQuery: string;
};

type Action = {
  getStockVideos: () => void;
  changePage: () => void;
  changeQuery: (newQuery: string) => void;
};

export const stockVideosStore = create<Store & Action>((set, get) => ({
  error: '',
  currentPage: 1,
  stockVideos: [],
  isLoaded: false,
  currentQuery: 'nature',
  isPreviewModalOpen: false,
  getStockVideos: async () => {
    set({ isLoaded: false });
    try {
      const queryStr = `?size=medium&orientation=landscape&locale=en-US&per_page=18&query=${
        get().currentQuery
      }&page=${get().currentPage}`;

      const { data } = await stockVideoApi.fetchVideos(queryStr);

      set({
        stockVideos: [...get().stockVideos, ...data.videos]
      });
    } catch (error) {
      if (error instanceof Error) {
        set({ error: error.message });
      }
    } finally {
      set({ isLoaded: true });
    }
  },
  changePage: () => {
    set((state) => ({ currentPage: state.currentPage + 1 }));
    get().getStockVideos();
  },
  changeQuery: (newQuery) => {
    set({ currentQuery: newQuery, stockVideos: [] });
    get().getStockVideos();
  }
}));
