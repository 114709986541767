import React from 'react';
import Modal from 'components/shared/Modal';
import Divider from 'components/Divider';
import talkingCreateCover from 'shared/ui/img/talkingCreateCover.png';
import agentCreateCover from 'shared/ui/img/agentCreateCover.png';
import { useNavigate } from 'react-router';
import {
  AVATARS_CREATE_PERSONA_ROUTE,
  CREATE_PERSONA_ROUTE
} from 'shared/model';
import { personaStore } from 'entities/persona';

const cards = [
  {
    name: 'Talking Photos',
    image: talkingCreateCover,
    items: ['Stock + Custom Talking Photos', 'No body movement']
  },
  {
    name: 'Avatars',
    image: agentCreateCover,
    items: [
      'Stock Avatars',
      'Sub 600ms',
      'Body movement',
      'Hyperrealistic',
      'Vision capabilities',
      'Custom Background'
    ]
  }
];
export const ChooseCreateAgentModal = () => {
  const nav = useNavigate();
  const { createAgentModal, setCreateAgentModal } = personaStore((state) => ({
    createAgentModal: state.createAgentModal,
    setCreateAgentModal: state.setCreateAgentModal
  }));
  const handleClick = (name: string) => {
    setCreateAgentModal(false);
    if (name === 'Standard') {
      nav(CREATE_PERSONA_ROUTE);
    } else {
      nav(AVATARS_CREATE_PERSONA_ROUTE);
    }
  };
  return (
    <Modal
      className={'ag-max-w-[640px] ag-max-h-[90vh] ag-overflow-auto'}
      open={createAgentModal}
      setOpen={(value) => setCreateAgentModal(!!value)}
    >
      <h2 className="ag-text-lg ag-font-semibold ag-text-neutral-900 ag-mb-5">
        Create Avatar
      </h2>
      <Divider />
      <div className={'ag-py-5 ag-px-6 ag-flex ag-gap-5'}>
        {cards.map((card) => (
          <div
            onClick={() => handleClick(card.name)}
            key={card.name}
            className={
              'ag-border ag-border-neutral-200 ag-rounded-xl ag-flex-1 ag-py-5 ag-pl-5 ag-pr-3 ag-cursor-pointer'
            }
          >
            <img
              src={card.image}
              alt={card.name}
              className={'ag-mb-5 ag-w-full'}
            />
            <div className={'ag-text-2xl ag-font-semibold'}>{card.name}</div>
            <ul className={'ag-p-5'}>
              {card.items.map((item) => (
                <li
                  key={item}
                  className="not: ag-mb-3 ag-text-neutral-600 ag-relative before:content-['•'] before:ag-w-2 before:ag-h-2 before:ag-bg-primary-600 before:ag-absolute ag-pl-5 before:ag-rounded-full before:ag-top-1/2 before:-ag-left-0 before:ag--translate-y-1/2"
                >
                  {item}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </Modal>
  );
};
