import { AxiosResponse } from 'axios';
import { Conversation } from '../model';
import { tavusHttpService } from 'shared/api';

const tavusConversationsEndpoint = '/conversation/';

export const conversationApi = {
  createConversation: async (personaId: string) => {
    const { data }: AxiosResponse<Conversation> = await tavusHttpService.post(
      tavusConversationsEndpoint + `start/${personaId}`
    );

    return data;
  },
  endConversation: async (conversationId: string) => {
    const data: AxiosResponse<any> = await tavusHttpService.post(
      `${tavusConversationsEndpoint}${conversationId}/end`
    );

    return data;
  }
};
