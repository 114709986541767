import React from 'react';
import clsx from 'clsx';
import { Replica } from 'entities/tavus/replica';
import { replicaStore } from 'entities/tavus/replica/model/replicaStore';

type AvatarItemProps = {
  replica: Replica;
  onChooseReplica: (replica: Replica) => void;
  isActive?: boolean;
};

export const AvatarListItem: React.FC<AvatarItemProps> = ({
  replica,
  onChooseReplica,
  isActive
}) => {
  const getReplicaVideo = replicaStore((state) => state.getReplicaVideo);
  const handleChooseAvatar = () => {
    onChooseReplica(replica);
  };
  return (
    <div
      onClick={handleChooseAvatar}
      className={clsx(
        `ag-relative ag-group ag-h-fit ag-w-24 ag-truncate`,
        isActive ? 'ag-cursor-default' : 'ag-cursor-pointer'
      )}
    >
      {getReplicaVideo(replica.replica_id) && (
        <video
          preload="metadata"
          src={getReplicaVideo(replica.replica_id)}
          className={`ag-w-24 ag-aspect-square ag-border ag-object-cover ag-rounded ${
            isActive ? 'ag-border-secondary' : 'ag-border-neutral-100'
          } `}
        />
      )}
      <span className="ag-text-neutral-600 ag-text-sm ag-inline ag-bottom-2 ag-left-2">
        {replica?.replica_name}
      </span>
    </div>
  );
};
