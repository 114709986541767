import React, { FC, useState } from 'react';
import { FormikProps } from 'formik';
import clsx from 'clsx';
import FormError from 'components/Forms/FormError/FormError';
import { ITavusPersonaFormik } from 'entities/tavus/persona';
import { aiModels } from 'entities/tavus/ai-model';
import { ChooseModelAiModal } from 'features/choose-model-ai';
import { VscChevronDown } from 'react-icons/vsc';

type Props = {
  formik: FormikProps<ITavusPersonaFormik>;
  classNameBg?: string;
};

export const TavusPersonaDataForm: FC<Props> = ({
  formik,
  classNameBg = 'ag-bg-neutral-800'
}) => {
  const [openChoseAiModelModal, setOpenChoseAiModelModal] = useState(false);
  return (
    <div className="ag-space-y-10">
      <div className="ag-flex ag-flex-col ag-relative">
        <label className="ag-mb-4 ag-text-white" htmlFor="name">
          <div>Name</div>
          <div className="ag-text-sm ag-text-neutral-400">
            What’s the name of your Video Agent?
          </div>
        </label>

        <input
          className={clsx(
            classNameBg,
            'ag-border ag-border-neutral-600 ag-py-2 ag-px-3 ag-rounded-md ag-text-sm ag-outline-none ag-ring-0 focus:ag-outline-none focus:ag-ring-0',
            formik.errors.name && formik.touched.name
              ? 'ag-text-red-900  focus:ag-border-red-500'
              : 'ag-text-white placeholder:ag-text-gray-400 focus:ag-border-primary-800'
          )}
          placeholder="My Video Agent"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.name}
          name="name"
          id="name"
        />
        {formik.errors.name && formik.touched.name && (
          <FormError>{formik.errors.name}</FormError>
        )}
      </div>

      <div className="ag-flex ag-flex-col">
        <label className="ag-mb-4 ag-text-white" htmlFor="systemPrompt">
          <div>Description</div>
          <div className="ag-text-sm ag-text-neutral-400">
            In a few words, describe your Video Agent.
          </div>
        </label>
        <textarea
          className={clsx(
            classNameBg,
            'ag-border ag-border-neutral-600 ag-py-2 ag-px-3 ag-rounded-md ag-text-sm ag-outline-none ag-ring-0 focus:ag-outline-none focus:ag-ring-0',
            formik.errors.systemPrompt && formik.touched.systemPrompt
              ? 'ag-text-red-900  focus:ag-border-red-500'
              : 'ag-text-white placeholder:ag-text-gray-400 focus:ag-border-primary-800'
          )}
          placeholder="For example, “As a Life Coach, you are a dedicated professional who specializes in...”"
          name="systemPrompt"
          id="systemPrompt"
          rows={3}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.systemPrompt}
        />
        <div className="ag-flex ag-items-center ag-justify-between">
          <div>
            {formik.errors.systemPrompt && formik.touched.systemPrompt && (
              <FormError>{formik.errors.systemPrompt}</FormError>
            )}
          </div>
          <div className="ag-text-xs ag-text-neutral-400 ag-text-right ag-mt-1">
            {formik.values.systemPrompt.length}/80 characters
          </div>
        </div>
      </div>

      <div className="ag-flex ag-flex-col ag-relative">
        <label className="ag-mb-4 ag-text-white" htmlFor="name">
          <div>Model</div>
          <div className="ag-text-sm ag-text-neutral-400">
            Select the AI model that powers your agent&apos;s responses and
            interactions
          </div>
        </label>
        <div className="ag-relative">
          <input
            className={clsx(
              classNameBg,
              'ag-border ag-w-full ag-border-neutral-600 ag-py-2 ag-px-3 ag-rounded-md ag-text-sm ag-outline-none ag-ring-0 focus:ag-outline-none focus:ag-ring-0',
              formik.errors.name && formik.touched.name
                ? 'ag-text-red-900  focus:ag-border-red-500'
                : 'ag-text-white placeholder:ag-text-gray-400 focus:ag-border-primary-800'
            )}
            placeholder="My Video Agent"
            onClick={() => setOpenChoseAiModelModal(true)}
            value={
              aiModels.find((model) => model.value === formik.values.model)
                ?.name
            }
            name="model"
            id="name"
            readOnly
          />
          <VscChevronDown
            className={`ag-right-2 ag-transform ag-top-1/2 -ag-translate-y-1/2 ag-absolute ${
              openChoseAiModelModal && 'ag-rotate-180'
            } `}
          />
        </div>

        {formik.errors.name && formik.touched.name && (
          <FormError>{formik.errors.name}</FormError>
        )}
      </div>

      <div className="ag-flex ag-flex-col">
        <label className="ag-mb-4 ag-text-white" htmlFor="greeting">
          <div>Greeting(Optional)</div>
          <div className="ag-text-sm ag-text-neutral-400">
            This is the first thing they’ll say when starting a new
            conversation.
          </div>
        </label>
        <textarea
          className={clsx(
            classNameBg,
            'ag-border ag-border-neutral-600 ag-py-2 ag-px-3 ag-rounded-md ag-text-sm ag-outline-none ag-ring-0 focus:ag-outline-none focus:ag-ring-0',
            formik.errors.customGreeting && formik.touched.customGreeting
              ? 'ag-text-red-900  focus:ag-border-red-500'
              : 'ag-text-white placeholder:ag-text-gray-400 focus:border-primary-800'
          )}
          placeholder={`For example, “Hello! I'm your personal assistant. How can I assist you today?”`}
          name="customGreeting"
          id="greeting"
          rows={5}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.customGreeting}
        />
        <div className="ag-flex ag-items-center ag-justify-between">
          <div>
            {formik.errors.customGreeting && formik.touched.customGreeting && (
              <FormError>{formik.errors.customGreeting}</FormError>
            )}
          </div>
          <div className="ag-text-xs ag-text-neutral-400 ag-text-right ag-mt-1">
            {formik.values.customGreeting.length}/500 characters
          </div>
        </div>
      </div>

      <div className="ag-flex ag-flex-col">
        <label className="ag-mb-4 ag-text-white" htmlFor="greeting">
          <div>Instructions (Optional)</div>
          <div className="ag-text-sm ag-text-neutral-400">
            Define how your video agent will interact with users. Provide
            detailed guidance on the Video Agent&apos;s role, personality and
            expertise.
          </div>
        </label>
        <textarea
          className={clsx(
            classNameBg,
            'ag-border ag-border-neutral-600 ag-py-2 ag-px-3 ag-rounded-md ag-text-sm ag-outline-none ag-ring-0 focus:ag-outline-none focus:ag-ring-0',
            formik.errors.context && formik.touched.context
              ? 'ag-text-red-900  focus:ag-border-red-500'
              : 'ag-text-white placeholder:ag-text-gray-400 focus:border-primary-800'
          )}
          placeholder={`For example, "Be a friendly and knowledgeable science tutor focused on physics. Explain topics like force and energy in an easy-to-understand way. If a user asks about a topic outside of physics, politely steer the conversation back to physics by saying something like, 'I’m here to help with physics topics. Can we go back to discussing force and energy?' Keep the tone upbeat and supportive to make learning fun and engaging."`}
          name="context"
          id="context"
          rows={5}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.context}
        />
        <div className="ag-flex ag-items-center ag-justify-between">
          <div>
            {formik.errors.context && formik.touched.context && (
              <FormError>{formik.errors.context}</FormError>
            )}
          </div>
        </div>
      </div>
      <ChooseModelAiModal
        models={aiModels.map((item) => ({
          value: item.value,
          description: item.description,
          name: item.name,
          icon: item.iconBlack
        }))}
        onChoose={(value) => formik.setFieldValue('model', value)}
        setOpen={setOpenChoseAiModelModal}
        isOpen={openChoseAiModelModal}
      />
    </div>
  );
};
