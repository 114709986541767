import { useNavigate } from 'react-router-dom';
import { GoPlus } from 'react-icons/go';
import { useAuthStore } from 'stores/useAuthStore';
import { creditStore } from 'entities/credit';
import { tavusPersonaStore } from 'entities/tavus/persona';
import { TavusPersona } from './TavusPersona';
import { clsx } from 'clsx';

export const TavusPersonasList = () => {
  const { tavusPersonas } = tavusPersonaStore((state) => ({
    tavusPersonas: state.tavusPersonas
  }));
  const isAuth = useAuthStore((state) => state.isAuth);
  const userPersonas = tavusPersonas?.filter((item) => item.user_id);
  const stockPersonas = tavusPersonas?.filter((item) => !item.user_id);
  return (
    <>
      {isAuth && (
        <>
          <h2
            className={clsx(
              'ag-font-semibold dark:ag-text-white',
              userPersonas?.length ? 'ag-mb-4' : 'ag-mb-4'
            )}
          >
            My Video Agents
          </h2>
          <div
            className={clsx(
              'ag-w-full ag-grid-cols-2 md:ag-grid-cols-3 lg:ag-grid-cols-4 xl:ag-grid-cols-5 3xl:ag-grid-cols-6 5xl:ag-grid-cols-8 ag-gap-4 ag-flex-wrap ag-pb-6',
              userPersonas?.length ? 'ag-grid' : ''
            )}
          >
            {userPersonas?.length ? (
              userPersonas.map((persona, ind) => (
                <TavusPersona key={persona.id + ind} persona={persona} />
              ))
            ) : (
              <div className={'ag-text-neutral-600 ag-w-full'}>
                You don’t have any Video Agent yet.
              </div>
            )}
          </div>
        </>
      )}

      <h2 className="ag-font-semibold dark:ag-text-white ag-mb-8">
        Stock Video Agents
      </h2>
      <div className="ag-w-full ag-grid ag-grid-cols-2 md:ag-grid-cols-3 lg:ag-grid-cols-4 xl:ag-grid-cols-5 3xl:ag-grid-cols-6 5xl:ag-grid-cols-8 ag-gap-4 ag-flex-wrap ag-pb-6">
        {stockPersonas.map((persona, ind) => (
          <TavusPersona key={persona.id + ind} persona={persona} />
        ))}
      </div>
    </>
  );
};
