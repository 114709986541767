import { FC, useState } from 'react';
import Voice from '../../../components/SideBar/Voices/Voice';
import { IAvatar, IVoice } from 'shared/model';
import voiceStore from '../../../stores/useVoiceStore';
import { FormikErrors } from 'formik';
import { ICreatePersonaReq } from 'entities/persona';
import { usePlaySound } from 'features/play-sound';
import { VoiceFilter } from 'entities/voice/ui/VoiceFilter';
import { Loader } from 'shared/ui';
import Dropdown from 'components/shared/Dropdown';
import { VscChevronDown } from 'react-icons/vsc';
import { capitalize } from 'shared/lib';
import { useNotificationStore } from 'stores/useNotificationStore';

type Props = {
  avatarGender?: IAvatar['gender'];
};

export const TavusChooseVoice: FC<Props> = ({ avatarGender }) => {
  const { filteredVoices, loading } = voiceStore();
  const { showMessage } = useNotificationStore((state) => ({
    showMessage: state.showMessage
  }));
  const handleChangeVoice = () => {
    showMessage('Coming soon');
  };

  const { currentAudio, isPlaying, handlePauseAudio, handlePlayAudio } =
    usePlaySound();

  return (
    <>
      <Dropdown
        wrapperClassName="ag-relative ag-mb-3"
        icon={<VscChevronDown className={`ag-my-auto ag-transform`} />}
        labelClassName="ag-line-clamp-1"
        label={'Default'}
        buttonClassName="ag-px-4 ag-py-2.5 ag-w-full"
      />
      <div className="ag-mb-6">
        <VoiceFilter />
      </div>
      <div className="ag-h-full ag-flex ag-flex-col ag-gap-y-2 ag-overflow-auto ag-scrollbar-none ag-text-white ag-pb-20">
        {loading ? (
          <div className="ag-flex ag-justify-center">
            <Loader />
          </div>
        ) : filteredVoices?.length ? (
          filteredVoices.map((voice) => (
            <Voice
              key={voice.voiceId + voice.gender}
              voice={voice}
              doesGenderMatch={
                avatarGender
                  ? avatarGender.toLowerCase() === voice.gender
                  : true
              }
              onPause={handlePauseAudio}
              onPlay={handlePlayAudio}
              currentAudio={currentAudio}
              isPlaying={isPlaying}
              onHandleChange={handleChangeVoice}
            />
          ))
        ) : (
          <div className="ag-text-base ag-w-full ag-mx-auto ag-p-3 ag-text-center">
            No voices found matching filters
          </div>
        )}
      </div>
    </>
  );
};
