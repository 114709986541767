import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState
} from 'react';
import Modal from 'components/shared/Modal';
import Divider from 'components/Divider';
import Dropdown from 'components/shared/Dropdown';
import { BsFilter } from 'react-icons/bs';
import Checkbox from 'components/shared/Checkbox';
import { AvatarOptions, IAvatar } from 'shared/model';
import { GenderOptions } from 'components/SideBar/Avatars/CustomTalkingPhotoFlow/types/types';
import CustomTalkingPhotoInfo from 'components/SideBar/Avatars/CustomTalkingPhotoFlow/components/CustomTalkingPhotoInfo';
import { useAuthStore } from 'stores/useAuthStore';
import { avatarStore } from 'entities/avatar';
import { TalkingPhotoListItem } from './TalkingPhotoListItem';
import { IoSearchOutline } from 'react-icons/io5';
import { useDebounce } from 'shared/lib';
import { FilledButton, OutlinedButton } from 'shared/ui';

interface IProps {
  setOpen: Dispatch<SetStateAction<boolean>>;
  open: boolean;
}

export const TalkingPhotoListModal: FC<IProps> = ({ setOpen, open }) => {
  const { isAuth, toggleCreateAccModal } = useAuthStore();
  const [typeFilters, setTypeFilters] = useState<AvatarOptions[]>([]);
  const [nameInputValue, setNameInputValue] = useState('');
  const [nameFilterValue, setNameFilterValue] = useState('');
  const changeFilterNameValue = useDebounce(setNameFilterValue, 500);

  useEffect(() => {
    changeFilterNameValue(nameInputValue);
  }, [nameInputValue]);

  useEffect(() => {
    if (!open) {
      setSelectedAvatar(null);
    } else {
      setSelectedAvatar(activeAvatar);
    }
  }, [open]);
  const {
    activeAvatar,
    selectAvatar,
    filteredTalkingPhotos,
    filteredCustomTalkingPhotos,
    genderFilters,
    setGenderFilters,
    resetGenders,
    toggleUploadAvatar
  } = avatarStore((state) => ({
    activeAvatar: state.activeAvatar,
    selectAvatar: state.selectAvatar,
    filteredTalkingPhotos: state.filteredTalkingPhotos,
    filteredCustomTalkingPhotos: state.filteredCustomTalkingPhotos,
    genderFilters: state.genderFilters,
    setGenderFilters: state.setGenderFilters,
    resetGenders: state.resetGenders,
    toggleUploadAvatar: state.toggleUploadAvatar
  }));

  const [selectedAvatar, setSelectedAvatar] = useState<IAvatar | null>(
    activeAvatar
  );

  const handleSetTypeFilter = (type: AvatarOptions) => {
    const newFilter = [...typeFilters];
    if (newFilter.includes(type)) {
      newFilter.splice(newFilter.indexOf(type), 1);
    } else {
      newFilter.push(type);
    }
    setTypeFilters(newFilter);
  };

  const filterByName = (avatar: IAvatar) => {
    return avatar.name.toLowerCase().includes(nameFilterValue.toLowerCase());
  };

  return (
    <Modal
      className={
        'ag-max-w-[40.5rem] ag-max-h-[calc(100vh-5rem)] ag-overflow-y-auto'
      }
      noPadding
      setOpen={(value) => {
        setOpen(value);
      }}
      open={open}
    >
      <div>
        <h2 className={'ag-p-6 ag-font-semibold ag-text-lg'}>Avatars</h2>
        <Divider />
      </div>
      <div>
        <div className="mx-4 ag-p-4 z-20 ag-flex">
          <div className={'ag-relative'}>
            <IoSearchOutline
              size={20}
              className={
                'ag-absolute ag-text-neutral-500 ag-left-3 ag-top-1/2 ag-transform -ag-translate-y-1/2'
              }
            />
            <input
              className="ag-rounded-md ag-py-2 ag-pl-10 ag-pr-3 ag-border-neutral-300 ag-max-w-80 ag-h-10 ag-outline-none ag-ring-0 focus:ag-ring-0 focus:ag-outline-none focus:ag-border-primary-800 ag-mr-2"
              type="text"
              value={nameInputValue}
              onChange={(e) => setNameInputValue(e.target.value)}
              placeholder={'Avatar name'}
            />
          </div>
          <Dropdown
            buttonClassName={
              'ag-w-10 ag-h-10 !ag-border-neutral-300 ag-flex ag-justify-center ag-items-center ag-rounded-md ag-border'
            }
            className={'!ag-border-neutral-300 ag-bg-white'}
            icon={
              <BsFilter size={20} className="w-5 h-5 ag-text-primary-600" />
            }
          >
            <div className="ag-pt-5 ag-pb-4 ag-text-neutral-900">
              <div className="ag-pl-3.5 ag-pr-4 ag-flex ag-justify-between ag-w-full ag-items-baseline">
                <button
                  className={`ag-text-sm ag-font-inter ag-font-semibold ${
                    typeFilters.length || genderFilters.length
                      ? 'text-secondary'
                      : 'text-white pointer-events-none'
                  }`}
                  onClick={() => {
                    setTypeFilters([]);
                    resetGenders();
                  }}
                >
                  Reset
                </button>
              </div>
              <div className="ag-flex ag-flex-col ag-items-start ag-gap-4 ag-justify-evenly ag-mt-5 ag-pl-3.5 ag-pr-4">
                <Checkbox
                  label="Talking Photo"
                  onClick={(value) =>
                    handleSetTypeFilter(value as AvatarOptions)
                  }
                  choosenValues={typeFilters}
                  value="talkingPhoto"
                />
                <Checkbox
                  label="Avatar"
                  onClick={(value) =>
                    handleSetTypeFilter(value as AvatarOptions)
                  }
                  choosenValues={typeFilters}
                  value="avatar"
                />
                <Checkbox
                  label="Custom"
                  onClick={(value) =>
                    handleSetTypeFilter(value as AvatarOptions)
                  }
                  choosenValues={typeFilters}
                  value="custom"
                />
              </div>
              <Divider className="ag-w-full ag-my-2 ag-border-neutral-300" />
              <div className="ag-flex ag-flex-col ag-items-start ag-gap-4 ag-justify-evenly ag-pl-3.5 ag-pr-4">
                <Checkbox
                  label="Male"
                  onClick={(value) => setGenderFilters(value as GenderOptions)}
                  choosenValues={genderFilters}
                  value="male"
                />
                <Checkbox
                  label="Female"
                  onClick={(value) => setGenderFilters(value as GenderOptions)}
                  choosenValues={genderFilters}
                  value="female"
                />
              </div>
            </div>
          </Dropdown>
        </div>
        <div className="ag-px-4 ag-max-h-[calc(60vh-5rem)] ag-overflow-y-auto ag-scrollbar-none">
          <>
            {filteredCustomTalkingPhotos &&
              filteredCustomTalkingPhotos.filter(filterByName)?.length > 0 &&
              (!typeFilters.length || typeFilters.includes('custom')) && (
                <div>
                  <h3 className="ag-text-sm ag-font-semibold ag-mb-2">
                    My Avatars
                  </h3>
                  <div className="ag-flex ag-gap-2 ag-flex-wrap">
                    <div
                      onClick={() => {
                        isAuth ? toggleUploadAvatar() : toggleCreateAccModal();
                        setOpen(false);
                      }}
                      className={
                        'ag-cursor-pointer ag-w-24 ag-h-24 ag-bg-primary-200 ag-rounded-md ag-flex ag-justify-center ag-items-center ag-text-primary-600 ag-text-2xl'
                      }
                    >
                      +
                    </div>
                    {filteredCustomTalkingPhotos
                      .filter(filterByName)
                      ?.map((avatar, idx) => (
                        <TalkingPhotoListItem
                          key={avatar.id}
                          onChooseAvatar={(avatar) => setSelectedAvatar(avatar)}
                          isActive={selectedAvatar?.id === avatar.id}
                          avatar={avatar}
                        />
                      ))}
                  </div>
                </div>
              )}
            {filteredTalkingPhotos &&
              filteredTalkingPhotos.filter(filterByName)?.length > 0 &&
              (!typeFilters.length || typeFilters.includes('talkingPhoto')) && (
                <div className="ag-my-6">
                  <h3 className="ag-text-sm ag-font-semibold ag-mb-2">
                    Stock Avatars
                  </h3>
                  <div className="ag-flex ag-gap-2 ag-flex-wrap">
                    {filteredTalkingPhotos
                      .filter(filterByName)
                      ?.map((avatar, inx) => (
                        <TalkingPhotoListItem
                          key={inx}
                          avatar={avatar}
                          onChooseAvatar={(avatar) => setSelectedAvatar(avatar)}
                          isActive={selectedAvatar?.id === avatar.id}
                        />
                      ))}
                  </div>
                </div>
              )}
          </>
        </div>
        ;
      </div>
      <Divider />;
      <div className={'ag-p-6 ag-flex ag-justify-end ag-gap-2'}>
        <OutlinedButton
          onClick={() => {
            setOpen(false);
          }}
          className={'ag-py-2.5 ag-px-[18px]'}
        >
          Cancel
        </OutlinedButton>
        <FilledButton
          onClick={() => {
            if (selectedAvatar) {
              selectAvatar(selectedAvatar);
            }
            setOpen(false);
          }}
          className={'ag-py-2.5 ag-px-[18px]'}
        >
          Apply
        </FilledButton>
      </div>
      ;
    </Modal>
  );
};
