import React, { FC, useState } from 'react';
import { clsx } from 'clsx';
import FormError from 'components/Forms/FormError/FormError';
import { IKnowledgeReq, IPersona, IPersonaFormik } from 'entities/persona';
import { FormikProps } from 'formik';
import { ChooseModelAiModal } from 'features/choose-model-ai';
import { engines } from 'entities/engine';
import { VscChevronDown } from 'react-icons/vsc';
import { Knowledge } from './Knowledge';

interface IProps {
  formik: FormikProps<IPersonaFormik>;
  persona: IPersona;
}

export const EditPersonaForm: FC<IProps> = ({ formik, persona }) => {
  const [openChoseAiModelModal, setOpenChoseAiModelModal] = useState(false);
  const [files, setFiles] = useState<string[]>(
    (persona.knowledgeSources ?? []).map((kn) => kn.asset.name)
  );
  const handleChangeFiles = (files: string[]) => {
    setFiles(files);
  };
  return (
    <div>
      <div className="ag-flex ag-flex-col ag-relative ag-text-neutral-900 ag-font-medium ag-text-sm ag-mb-10">
        <div className={'ag-font-semibold ag-mb-4'}>LLM</div>
        <div className="ag-relative">
          <input
            className={clsx(
              'ag-border ag-w-full ag-border-neutral-300 ag-py-2 ag-px-3 ag-rounded-md ag-outline-none ag-ring-0 focus:ag-outline-none focus:ag-ring-0',
              formik.errors.name && formik.touched.name
                ? 'ag-text-red-900  focus:ag-border-red-500'
                : 'placeholder:ag-text-gray-400 focus:ag-border-primary-800'
            )}
            placeholder="My Video Agent"
            onClick={() => setOpenChoseAiModelModal(true)}
            value={
              engines.find((model) => model.value === formik.values.model)?.name
            }
            name="model"
            id="name"
            readOnly
          />
          <VscChevronDown
            className={`ag-right-2 ag-transform ag-top-1/2 -ag-translate-y-1/2 ag-absolute ${
              openChoseAiModelModal && 'ag-rotate-180'
            } `}
          />
        </div>
      </div>
      <div className="ag-flex ag-flex-col ag-relative ag-mb-6">
        <label
          className="ag-mb-1.5 ag-text-neutral-900 ag-text-sm ag-font-semibold"
          htmlFor="name"
        >
          <div>Instructions</div>
          <div className="ag-text-sm ag-font-normal ag-text-neutral-600">
            Define how your video agent will interact with users. Provide
            detailed guidance on the Video Agent&rsquo;s role, personality and
            expertise.
          </div>
        </label>

        <textarea
          className={clsx(
            'ag-border ag-text-neutral-900 ag-border-neutral-300 ag-py-2 ag-px-3 ag-rounded-md ag-text-sm ag-outline-none ag-ring-0 focus:ag-outline-none focus:ag-ring-0',
            formik.errors.definition && formik.touched.definition
              ? 'ag-text-red-900  focus:ag-border-red-500'
              : 'placeholder:ag-text-neutral-500 focus:ag-border-primary-800'
          )}
          placeholder="For example, “An expert in digital marketing strategies.”"
          name="definition"
          id="definition"
          rows={3}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.definition}
        />
        {formik.errors.definition && formik.touched.definition && (
          <FormError>{formik.errors.definition}</FormError>
        )}
      </div>
      <Knowledge
        files={files}
        formik={formik}
        onChangeFile={handleChangeFiles}
        onChange={async (value: IKnowledgeReq[]) => {
          await formik.setFieldValue('knowledgeSources', value);
          return Promise.resolve();
        }}
      />
      <div className="ag-flex ag-flex-col ag-relative ag-mb-6">
        <label
          className="ag-mb-1.5 ag-text-neutral-900 ag-text-sm ag-font-semibold"
          htmlFor="greeting"
        >
          <div>Greeting (Optional)</div>
          <div className="ag-text-sm ag-font-normal ag-text-neutral-600">
            This is the first thing they’ll say when starting a new
            conversation.
          </div>
        </label>

        <textarea
          className={clsx(
            'ag-border ag-text-neutral-900 ag-border-neutral-300 ag-py-2 ag-px-3 ag-rounded-md ag-text-sm ag-outline-none ag-ring-0 focus:ag-outline-none focus:ag-ring-0',
            formik.errors.greeting && formik.touched.greeting
              ? 'ag-text-red-900  focus:ag-border-red-500'
              : 'placeholder:ag-text-neutral-500 focus:ag-border-primary-800'
          )}
          placeholder="For example, “An expert in digital marketing strategies.”"
          name="greeting"
          id="greeting"
          rows={3}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.greeting}
        />
        {formik.errors.greeting && formik.touched.greeting && (
          <FormError>{formik.errors.greeting}</FormError>
        )}
      </div>
      <ChooseModelAiModal
        models={engines.map((item) => ({
          value: item.value,
          name: item.name,
          description: item.description,
          icon: item.iconBlack
        }))}
        onChoose={(value) => formik.setFieldValue('model', value)}
        setOpen={setOpenChoseAiModelModal}
        isOpen={openChoseAiModelModal}
      />
    </div>
  );
};
