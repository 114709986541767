import { FC, MouseEventHandler, useEffect, useState } from 'react';
import { BsFillPlusCircleFill } from 'react-icons/bs';
import ColorSelect from 'shared/ui/ColorSelect/ColorSelect';
import { clsx } from 'clsx';
import { useStockImages } from 'features/list-stock-images';
import { RxCrossCircled } from 'react-icons/rx';
import { AddCustomImage } from 'features/upload-custom-image';
import { useInView } from 'react-intersection-observer';
import { IoIosSearch } from 'react-icons/io';
import { cn, useDebounce } from 'shared/lib';
import AddCustomVideo from 'features/upload-custom-video/ui/AddCustomVideo';
import { stockVideosStore } from 'features/list-stock-videos';
import { Loader } from 'shared/ui';

const colorList = [
  '#FFFFFF',
  '#E5E5E5',
  '#FADBD8',
  '#FAD7A0',
  '#FCF3CF',
  '#D4EFDF',
  '#D6EAF8',
  '#D7BDE2',
  '#171717'
];

const gradientList = [
  'linear-gradient(0deg, #FFFFFF, #FFFFFF), radial-gradient(50% 50% at 50% 50%, #FFFFFF 22.32%, #C0C0C0 100%)',
  'radial-gradient(75.92% 75.92% at 50% 50%, #494949 0%, #161616 89.09%)'
];

const tabs = ['Color', 'Images', 'Videos'];

interface IProps {
  background: string;
  onChange: (background: string) => void;
  customImageBackgrounds: string[];
  customVideoBackgrounds: string[];
  setCustomImageBackgrounds: (backgrounds: string[]) => void;
  setCustomVideoBackgrounds: (backgrounds: string[]) => void;
  theme?: 'light' | 'dark';
}

export const ChooseBackground: FC<IProps> = ({
  background,
  onChange,
  customVideoBackgrounds,
  customImageBackgrounds,
  setCustomImageBackgrounds,
  setCustomVideoBackgrounds,
  theme = 'dark'
}) => {
  const {
    images,
    changeQuery,
    changePage,
    loaded: isImageLoaded
  } = useStockImages();

  const {
    getStockVideos,
    changeQueryVideo,
    changePageVideo,
    stockVideos,
    isVideoLoading
  } = stockVideosStore((state) => ({
    getStockVideos: state.getStockVideos,
    changeQueryVideo: state.changeQuery,
    changePageVideo: state.changePage,
    stockVideos: state.stockVideos,
    isVideoLoading: state.isLoaded
  }));

  useEffect(() => {
    getStockVideos();
  }, []);

  // @ts-ignore
  const handlePlayVideo = async (e: MouseEvent<HTMLVideoElement>) => {
    try {
      // to remove 'The play() request was interrupted by a call to pause()' error
      await (e.target as HTMLVideoElement).play();
    } catch (error) {}
  };

  // @ts-ignore
  const handlePauseVideo = (e: MouseEvent<HTMLVideoElement>) => {
    (e.target as HTMLVideoElement).pause();
  };

  const [currentTab, setCurrentTab] = useState(0);
  const [customColor, setCustomColor] = useState<string>('#F2F2F2');

  const { ref, inView } = useInView();
  const { ref: refVideo, inView: inViewVideo } = useInView();
  const [nameInputValue, setNameInputValue] = useState('');
  const [nameVideoInputValue, setNameVideoInputValue] = useState('');

  const handleSearch = useDebounce((value: string) => {
    if (value) {
      changeQuery(value);
    } else {
      changeQuery('video background');
    }
  }, 500);

  const handleSearchVideo = useDebounce((value: string) => {
    if (value) {
      changeQueryVideo(value);
    } else {
      changeQueryVideo('nature');
    }
  }, 500);

  useEffect(() => {
    handleSearch(nameInputValue);
  }, [nameInputValue]);

  useEffect(() => {
    handleSearchVideo(nameVideoInputValue);
  }, [nameVideoInputValue]);

  useEffect(() => {
    inView && changePage();
  }, [inView]);

  useEffect(() => {
    inViewVideo && changePageVideo();
  }, [inViewVideo]);

  return (
    <div className="ag-min-h-96 ag-pt-2 ag-mb-16">
      <div
        className={cn(
          'ag-flex ag-border-b ag-mb-6',
          theme === 'dark' ? 'ag-border-neutral-800' : 'ag-border-neutral-200'
        )}
      >
        {tabs.map((tab, index) => (
          <div
            key={tab}
            className={clsx(
              'ag-flex-1 ag-font-semibold ag-cursor-pointer ag-text-center ag-py-3',
              currentTab === index
                ? theme === 'dark'
                  ? 'ag-text-neutral-100 ag-border-b-2 ag-border-neutral-100'
                  : 'ag-text-primary-700 ag-border-b-2 ag-border-primary-700'
                : 'ag-text-neutral-500'
            )}
            onClick={() => setCurrentTab(index)}
          >
            {tab}
          </div>
        ))}
      </div>
      {currentTab === 0 && (
        <>
          <div className="ag-text-sm ag-font-semibold ag-mb-4">
            Solid colours
          </div>
          <div className="ag-flex ag-flex-wrap ag-gap-2 ag-mb-6">
            <div
              className={clsx(
                'ag-w-16 ag-h-16 ag-rounded-lg ag-border ag-cursor-pointer ag-flex ag-justify-center ag-items-center',
                background === ''
                  ? 'ag-border-[3px] ag-border-primary-600'
                  : theme === 'dark'
                  ? 'ag-border-neutral-700'
                  : 'ag-border-neutral-200'
              )}
              onClick={() => onChange('')}
            >
              <RxCrossCircled size={32} />
            </div>
            <ColorSelect
              className={clsx(
                'ag-w-16 ag-h-16 ag-rounded-lg ag-border',
                background === customColor
                  ? 'ag-border-[3px] ag-border-primary-600'
                  : theme === 'dark'
                  ? 'ag-border-neutral-700'
                  : 'ag-border-neutral-200'
              )}
              onChange={(color) => {
                onChange(color);
                setCustomColor(color);
              }}
              pickerPosition="right"
              disableTransparent
              color={customColor}
            >
              <BsFillPlusCircleFill color="black" size={32} />
            </ColorSelect>
            {colorList.map((color) => (
              <div
                className={clsx(
                  'ag-w-16 ag-h-16 ag-rounded-lg ag-border ag-cursor-pointer',
                  background === color
                    ? 'ag-border-[3px] ag-border-primary-600'
                    : theme === 'dark'
                    ? 'ag-border-neutral-700'
                    : 'ag-border-neutral-200'
                )}
                onClick={() => onChange(color)}
                style={{ background: color }}
                key={color}
              />
            ))}
          </div>
          <div className="ag-text-sm ag-font-semibold ag-mb-4">Gradients</div>
          <div className="ag-flex ag-flex-wrap ag-gap-2">
            {gradientList.map((color) => (
              <div
                className={clsx(
                  'ag-w-16 ag-h-16 ag-rounded-lg ag-border ag-cursor-pointer',
                  background === color
                    ? 'ag-border-[3px] ag-border-primary-600'
                    : theme === 'dark'
                    ? 'ag-border-neutral-700'
                    : 'ag-border-neutral-200'
                )}
                onClick={() => onChange(color)}
                style={{ background: color }}
                key={color}
              />
            ))}
          </div>
        </>
      )}
      {currentTab === 1 && (
        <div>
          <div className={'ag-flex ag-items-center ag-justify-between ag-mb-6'}>
            <div className={'ag-relative'}>
              <IoIosSearch
                size={20}
                className={
                  'ag-text-neutral-100 ag-absolute ag-left-3 ag-top-1/2 ag-transform -ag-translate-y-1/2'
                }
              />
              <input
                value={nameInputValue}
                onChange={(e) => setNameInputValue(e.target.value)}
                className={clsx(
                  'ag-border ag-py-3 ag-pl-10 ag-pr-3 ag-rounded-md ag-text-sm ag-outline-none ag-ring-0 focus:ag-outline-none focus:ag-ring-0 focus:ag-border-primary-800 ag-max-w-80',
                  theme === 'dark'
                    ? 'ag-bg-neutral-800 ag-border-neutral-600 ag-text-white placeholder:ag-text-gray-400'
                    : 'ag-bg-white ag-border-neutral-300 placeholder:ag-text-neutral-500 ag-text-neutral-900'
                )}
                placeholder="Search an image"
              />
            </div>
            <AddCustomImage
              onUpload={(image) => {
                const value = `image;url(${image})`;
                onChange(value);
                setCustomImageBackgrounds([value, ...customImageBackgrounds]);
              }}
              inputId={'customImage'}
            />
          </div>

          <div className={'ag-grid ag-grid-cols-5 ag-gap-2'}>
            {customImageBackgrounds.map((image, index) => (
              <div
                key={image}
                className={clsx(
                  'ag-max-w-24 ag-aspect-square ag-h-24 ag-rounded-lg ag-border ag-cursor-pointer',
                  background === image
                    ? 'ag-border-[3px] ag-border-primary-600'
                    : theme === 'dark'
                    ? 'ag-border-neutral-700'
                    : 'ag-border-neutral-200'
                )}
                onClick={() => {
                  onChange(image);
                }}
                style={{
                  background: image.replace('image;', ''),
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover'
                }}
              />
            ))}

            {images.map((image, index) => (
              <div
                ref={images.length - 1 === index ? ref : null}
                className={clsx(
                  'ag-max-w-24 ag-aspect-square ag-rounded-lg ag-border ag-cursor-pointer ag-bg-contain',
                  background === `image;url(${image.regular})`
                    ? 'ag-border-[3px] ag-border-primary-600'
                    : theme === 'dark'
                    ? 'ag-border-neutral-700'
                    : 'ag-border-neutral-200'
                )}
                onClick={() => onChange(`image;url(${image.regular})`)}
                style={{
                  background: `url(${image.thumb})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover'
                }}
                key={image.regular}
              />
            ))}

            {!isImageLoaded && <Loader size={100} />}
          </div>
        </div>
      )}
      {currentTab === 2 && (
        <div>
          <div className={'ag-flex ag-items-center ag-justify-between ag-mb-6'}>
            <div className={'ag-relative'}>
              <IoIosSearch
                size={20}
                className={
                  'ag-text-neutral-100 ag-absolute ag-left-3 ag-top-1/2 ag-transform -ag-translate-y-1/2'
                }
              />
              <input
                value={nameVideoInputValue}
                onChange={(e) => setNameVideoInputValue(e.target.value)}
                className={clsx(
                  'ag-border ag-py-3 ag-pl-10 ag-pr-3 ag-rounded-md ag-text-sm ag-outline-none ag-ring-0 focus:ag-outline-none focus:ag-ring-0 focus:ag-border-primary-800 ag-max-w-80',
                  theme === 'dark'
                    ? 'ag-bg-neutral-800 ag-border-neutral-600 ag-text-white placeholder:ag-text-gray-400'
                    : 'ag-bg-white ag-border-neutral-300 placeholder:ag-text-neutral-500 ag-text-neutral-900'
                )}
                placeholder="Search a video"
              />
            </div>
            <AddCustomVideo
              onUpload={(video) => {
                const value = `video;${video}`;
                setCustomVideoBackgrounds([value, ...customVideoBackgrounds]);
                onChange(value);
              }}
              inputId={'customImage'}
            />
          </div>
          <div className={'ag-grid ag-grid-cols-5 ag-gap-2'}>
            {customVideoBackgrounds.map((video) => (
              <video
                onMouseOver={handlePlayVideo}
                onMouseOut={handlePauseVideo}
                key={video}
                className={clsx(
                  'ag-aspect-square ag-rounded-lg ag-border ag-cursor-pointer ag-object-cover',
                  background === video
                    ? 'ag-border-[3px] ag-border-primary-600'
                    : theme === 'dark'
                    ? 'ag-border-neutral-700'
                    : 'ag-border-neutral-200'
                )}
                onClick={() => onChange(video)}
                src={video.replace('video;', '')}
              />
            ))}
            {stockVideos.map((video, index) => (
              <video
                onMouseOver={handlePlayVideo}
                onMouseOut={handlePauseVideo}
                src={video.video_files[0].link}
                key={video.id}
                ref={stockVideos.length - 1 === index ? refVideo : null}
                className={clsx(
                  'ag-aspect-square ag-rounded-lg ag-border ag-cursor-pointer ag-object-cover',
                  background ===
                    `video;${
                      video.video_files.find((item) => item.width === 1920)
                        ?.link
                    }`
                    ? 'ag-border-[3px] ag-border-primary-600'
                    : theme === 'dark'
                    ? 'ag-border-neutral-700'
                    : 'ag-border-neutral-200'
                )}
                onClick={() =>
                  onChange(
                    `video;${
                      video.video_files.find((item) => item.width === 1920)
                        ?.link
                    }`
                  )
                }
              />
            ))}

            {isVideoLoading && <Loader size={100} />}
          </div>
        </div>
      )}
    </div>
  );
};
