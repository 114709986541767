import React, { FC, useState } from 'react';
import { clsx } from 'clsx';
import FormError from 'components/Forms/FormError/FormError';
import { FormikProps } from 'formik';
import { ChooseModelAiModal } from 'features/choose-model-ai';
import { VscChevronDown } from 'react-icons/vsc';
import { ITavusPersona, ITavusPersonaFormik } from 'entities/tavus/persona';
import { aiModels } from 'entities/tavus/ai-model';

interface IProps {
  formik: FormikProps<ITavusPersonaFormik>;
  persona: ITavusPersona;
}

export const AvatarEditPersonaForm: FC<IProps> = ({ formik, persona }) => {
  const [openChoseAiModelModal, setOpenChoseAiModelModal] = useState(false);
  return (
    <div>
      <div className="ag-flex ag-flex-col ag-relative ag-mb-6">
        <label
          className="ag-mb-1.5 ag-text-neutral-900 ag-text-sm ag-font-semibold"
          htmlFor="name"
        >
          <div>Instructions (Optional)</div>
          <div className="ag-text-sm ag-font-normal ag-text-neutral-600">
            Define how your video agent will interact with users. Provide
            detailed guidance on the Video Agent&rsquo;s role, personality and
            expertise.
          </div>
        </label>

        <textarea
          className={clsx(
            'ag-border ag-text-neutral-900 ag-border-neutral-300 ag-py-2 ag-px-3 ag-rounded-md ag-text-sm ag-outline-none ag-ring-0 focus:ag-outline-none focus:ag-ring-0',
            formik.errors.context && formik.touched.context
              ? 'ag-text-red-900  focus:ag-border-red-500'
              : 'placeholder:ag-text-neutral-500 focus:ag-border-primary-800'
          )}
          placeholder="For example, “An expert in digital marketing strategies.”"
          name="context"
          id="context"
          rows={3}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.context}
        />
        {formik.errors.context && formik.touched.context && (
          <FormError>{formik.errors.context}</FormError>
        )}
      </div>
      <div className="ag-flex ag-flex-col ag-relative ag-mb-6">
        <label
          className="ag-mb-1.5 ag-text-neutral-900 ag-text-sm ag-font-semibold"
          htmlFor="greeting"
        >
          <div>Greeting (Optional)</div>
          <div className="ag-text-sm ag-font-normal ag-text-neutral-600">
            This is the first thing they’ll say when starting a new
            conversation.
          </div>
        </label>

        <textarea
          className={clsx(
            'ag-border ag-text-neutral-900 ag-border-neutral-300 ag-py-2 ag-px-3 ag-rounded-md ag-text-sm ag-outline-none ag-ring-0 focus:ag-outline-none focus:ag-ring-0',
            formik.errors.customGreeting && formik.touched.customGreeting
              ? 'ag-text-red-900  focus:ag-border-red-500'
              : 'placeholder:ag-text-neutral-500 focus:ag-border-primary-800'
          )}
          placeholder="For example, “An expert in digital marketing strategies.”"
          name="customGreeting"
          id="customGreeting"
          rows={3}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.customGreeting}
        />
        {formik.errors.customGreeting && formik.touched.customGreeting && (
          <FormError>{formik.errors.customGreeting}</FormError>
        )}
      </div>
      <ChooseModelAiModal
        models={aiModels.map((item) => ({
          value: item.value,
          name: item.name,
          description: item.description,
          icon: item.iconBlack
        }))}
        onChoose={(value) => formik.setFieldValue('model', value)}
        setOpen={setOpenChoseAiModelModal}
        isOpen={openChoseAiModelModal}
      />
    </div>
  );
};
